import { totalNavbarHeight } from 'theme/navbar'

const NEWSLETTER_SUBSCRIPTION_CONTENT_TYPES = [
  'prophecy_report',
  'daily_bible_verse',
  'events_and_new_releases',
]

const EMAIL_REGEXP = /^[^@\s]+@([^@\s]+\.)+[^@\s]+$/
const ALREADY_SUBSCRIBED_STATUS_CODE = 409

document.addEventListener('turbolinks:load', () => {
  const newsletterForm = getForm()

  if (!newsletterForm) return

  const $subscribeBtn = $(newsletterForm).find('button[type="submit"]')
  const $subscribeEmail = $(newsletterForm).find('input[type="email"]')

  $subscribeEmail.on('keyup', () => {
    $subscribeEmail.parent().find('.invalid-feedback')[0].innerText = ''
    $subscribeEmail.removeClass('is-invalid')
    $subscribeEmail.parent().css('margin-bottom', '24px')
    if ($subscribeEmail.val().length === 0) {
      $subscribeBtn.prop('disabled', true)
    } else {
      $subscribeBtn.prop('disabled', false)
    }
  })

  $(newsletterForm).on('submit', (evt) => {
    evt.preventDefault()

    resetControlsValidity(newsletterForm)

    if (!validateForm(newsletterForm)) return

    disableBtn($subscribeBtn)

    $.post(requestSettings(newsletterForm))
      .done(() => processRequestSuccess(newsletterForm))
      .fail((response) => processRequestError(response, newsletterForm))
      .always(() => enableBtn($subscribeBtn))
  })
})

document.addEventListener('turbolinks:before-cache', () => {
  const newsletterForm = getForm()

  if (!newsletterForm) return

  resetFormState(newsletterForm)
})

function getForm() {
  return $('.newsletter_form')[0]
}

function resetFormState(form) {
  form.style.display = 'block'
  form.reset()
  $('.newsletter_content .description').css('display', 'block')
  $('.newsletter_content .message_container').css('display', 'none')
}

function resetControlsValidity(form) {
  forEachControl(form, markAsValid)
}

function forEachControl(form, func) {
  const formElements = form.elements

  // using regular 'for' because formElements is not an array
  // but an array-like object
  for (let i = 0; i < formElements.length; i++) {
    func(formElements[i])
  }
}

function validateForm(form) {
  const formElements = form.elements

  // Run each validation and return whether at least one failed.
  // Using ".reduce" instead of regular "&&" operator because due to nature of
  // "&&" operator if the first validation returns "false"
  // second is not triggered
  return [
    validateEmail(formElements.email),
    validateContentTypes(formElements),
  ].reduce((allValid, currentValid) => allValid && currentValid)
}

function validateEmail(emailInput) {
  const email = emailInput.value

  if (email.match(EMAIL_REGEXP)) return true

  markAsInvalid(emailInput, 'formatError')

  return false
}

function validateContentTypes(formElements) {
  if (checkedContentTypes(formElements).length > 0) return true

  markAsInvalid(formElements['content_types'], 'noSelectionError')
  forEachContentTypeInput(formElements, markAsInvalid)

  return false
}

function markAsInvalid(input, error = null) {
  input.classList.add('is-invalid')

  if (!error) return

  const errorMsg = input.dataset[error]
  $(input).parent().find('.invalid-feedback')[0].innerText = errorMsg
  $(input).parent().css('margin-bottom', '4px')
}

function markAsValid(input) {
  $(input).parent().find('.form-group').css('margin-bottom', '24px')
  input.classList.remove('is-invalid')
}

function checkedContentTypes(formElements) {
  return NEWSLETTER_SUBSCRIPTION_CONTENT_TYPES.filter((contentType) => {
    if (!formElements[contentType]) return false

    return formElements[contentType].checked
  })
}

function forEachContentTypeInput(formElements, func) {
  NEWSLETTER_SUBSCRIPTION_CONTENT_TYPES.forEach((contentType) => {
    func(formElements[contentType])
  })
}

function requestSettings(form) {
  return {
    url: form.action,
    data: JSON.stringify(requestParams(form)),
    contentType: 'application/json',
  }
}

function requestParams(form) {
  const formElements = form.elements
  const firstName = formElements.firstName ? formElements.firstName.value : ''
  const lastName = formElements.lastName ? formElements.lastName.value : ''
  const fullName = formElements.fullName ? formElements.fullName.value : ''

  return {
    newsletter_subscription: {
      first_name: firstName,
      last_name: lastName,
      full_name: fullName,
      email: formElements.email.value,
      content_types: checkedContentTypes(formElements),
    },
  }
}

function disableBtn(btn) {
  btn.prop('disabled', true)
  btn.find('.spinner').css('display', 'inline-block')
  btn.find('.label').css('display', 'none')
}

function enableBtn(btn) {
  if ($('#news_subscribe').length === 0) btn.prop('disabled', false)
  btn.find('.spinner').css('display', 'none')
  btn.find('.label').css('display', 'inline')
}

function showSuccessMessage(form) {
  showMessage(form, '.success_message_container')
}

function processRequestSuccess(form) {
  scrollToNewsletterElementTop()
  showSuccessMessage(form)
}

function scrollToNewsletterElementTop() {
  const navbarHeight = totalNavbarHeight()
  const $newsletterSubscriptionElement = $('#newsletter_subscription')
  if ($newsletterSubscriptionElement.length === 0) {
    return
  }

  const newsletterSubscriptionElementTop = $newsletterSubscriptionElement.offset()
    .top

  window.scrollTo({
    top: newsletterSubscriptionElementTop - navbarHeight,
    behavior: 'smooth',
  })
}

function showUnexpectedError(form) {
  showMessage(form, '.unexpected_error_container')
}

function showMessage(form, msgContainerClass) {
  if ($('#newsletter_subscription').length > 0) {
    form.style.display = 'none'
    $('.newsletter_content .description').css('display', 'none')
    $(`.newsletter_content ${msgContainerClass}`).css('display', 'block')
  }

  if ($('#news_subscribe').length > 0) {
    $(getForm()).find('input[type="email"]').val('')
    $('#news_subscribe_modal').modal({ show: true })
  }
}

function processRequestError(response, form) {
  scrollToNewsletterElementTop()

  if (response.status == ALREADY_SUBSCRIBED_STATUS_CODE) {
    showAlreadySubscribedError(form)
  } else {
    showUnexpectedError(form)
  }
}

function showAlreadySubscribedError(form) {
  markAsInvalid(form.elements.email, 'alreadySubscribedError')
}
